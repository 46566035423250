<template>
<section class="hero is-success is-fullheight">
  <!-- Hero head: will stick at the top -->
  <div class="hero-head">
    <header class="navbar navbar-color">
      <div class="container">
        <div class="navbar-brand">
            <router-link to="/" class="navbar-item">
                <img src="../../../public/logo.png" width="50" height="50">
                <h1 class="heading">Square<span>Garden</span></h1>
            </router-link>
            <span class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarHomepage" @click="toggleNavmenu">
                <span></span>
                <span></span>
                <span></span>
            </span>
        </div>
        <div id="navbarHomepage" class="navbar-menu" :class="mobile">
          <div class="navbar-start">
              <div class="navbar-item">
                    <button id="installbutton" class="button is-success is-inverted install-button hide"><i class="fas fa-download"></i>installieren</button>
                    <p id="iOSInstall" class="hide">To install press <img src="../../../public/img/add-to-homescreen.png" alt="add-to-home-screen" class=ios> and add to Homescreen</p>
              </div>
          </div>
          <div class="navbar-end">
            <div class="navbar-item">
                <span class="link"><router-link to="/product">{{ $t("nav.product.value")}}</router-link></span>
            </div>
            <div class="navbar-item">
                <span class="link"><router-link to="/contact">{{ $t("nav.contact.value")}}</router-link></span>
            </div>
            <div class="navbar-item">
                <div class="buttons">
                    <router-link to="/login" class="button is-success is-inverted"><i class="far fa-user"></i>Login</router-link>
                    <router-link to="/register" class="button is-success is-inverted"><i class="far fa-envelope-open"></i>{{ $t("nav.register.value")}}</router-link>
                    <languageSwitcher />
                </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>

  <!-- Hero content: will be in the middle -->
  <div class="hero-body">
    <div class="container has-text-centered">
      <p class="title">
        Square Garden!
      </p>
      <p class="subtitle">
          {{ $t('home.welcomeMessage.value') }} <!--, {value: "This is an example of content translation"}) }}-->
        <!--Welcome to Square Garden, the way to organize and structure your gardening! 🌱-->
      </p>
    </div>
  </div>
  <div class="hero-fotter">
  </div>
</section>

        
</template>

<script>
import {loginWithEmail} from "../../db/auth.js";
import languageSwitcher from "@/components/multilang/languageSwitcher";

export default {
    name: 'Login',
    components: {
        languageSwitcher,
    },
    data() {
        return { 
            email_error: "",
            email: "",
            auth_error: "",
            password: "",
            mobile: "",
        }
    },
    methods: {
        validateEmail(email) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                return false;
            } else {
                return true;
            }
        },
        login(e) {
            e.preventDefault();

            const email_field = document.getElementById("email-field");
            if(this.validateEmail(this.email)){
                //validirung der email
                email_field.classList.add("is-danger");
                this.email_error = "Not a valid email!";
            } else {
                //deleting errors
                email_field.classList.remove("is-danger");
                this.email_error = "";
            }
            //login prosses
            loginWithEmail(this.email, this.password)
            .then(res => {
                if(res.type === "success"){
                    //TODO: getting the Gardens for this User
                    this.$router.push("/dashboard");
                } else {
                    this.auth_error = res.message;
                }
            })
        },
        //UI Methods
        toggleNavmenu(e) {
            //Toggling the burger-menu
            if(!e.target.classList.contains("is-active")){
                e.target.classList.add("is-active")
                this.mobile="is-active";
            } else {
                e.target.classList.remove("is-active");
                this.mobile="";
            }
        }
    }
}
</script>

<style scoped>
h1.heading {
    text-transform: uppercase;
    color: #107869;
    font-size: 18px;
    margin-top: 8px;
    margin-left: 16px;
    font-weight: 600;
}

img.ios {
    width: 20px;
    height: 20px;
    border-radius: 2px;
}

.navbar-color {
    background: #94C973;
}

.link {
    font-size: 1.1rem;
    font-weight: 500;
}

i{
    margin-right: 5px;
}

.hide {
  visibility: hidden;
}

/*
.link :hover{
    background: #94C973;
    opacity: 80%;
    padding: 5px;
    border-radius: 5px;
}
*/
</style>