<template>
    <div class="select is-small is-success is-rounded">
        <select v-model="$i18n.locale"  @change="switchLang($event)">
            <option
                v-for="(lang,index) in langs"
                :key="`lang-${index}`"
                :value="lang">
                <p>{{lang}}</p>
            </option>
        </select>
    </div>
</template>

<script>
export default {
    name:"languageSwitcher",
    data() {
        return {
            langs: [
                "en",
                "de"
            ]
        }
    },
    methods: {
        switchLang(e) {
            //saving the current selected language
            this.$store.dispatch("lang/change_lang", e.target.value);
        }
    }
}
</script>

<style>

</style>